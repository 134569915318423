<template>
  <div class="detailView">
    <div class="pageContent-title">新增执行</div>
    <div class="detailView-with-actions mt-5" v-if="detailData">
      <a-form class="flex-form form-label-6" :form="form">
        <div class="form-base-width">
          <a-form-item label="服务名称">
            {{ detailData.opsName }}
          </a-form-item>
          <a-form-item label="在线文档">
            <a :href="detailData.fileUrl" target="blank">{{ detailData.fileUrl }}</a>
          </a-form-item>
          <a-form-item label="业务单据号">
            <a-input v-model="serviceNo" placeholder="请输入业务单据号"></a-input>
          </a-form-item>
          <a-form-item label="服务来源">
            <a-input v-model="serviceSource" placeholder="请输入服务来源"></a-input>
          </a-form-item>
          <!-- <a-form-item label="数据处理情况">
            <a-textarea
              v-decorator="['sceneNote']"
              :autoSize="{ minRows: 10, maxRows: 15 }"
              placeholder="请输入数据处理情况"
            ></a-textarea>
          </a-form-item> -->
          <a-form-item label="附资料">
            <PictureCardListUpload v-model="fileList"></PictureCardListUpload>
          </a-form-item>
          <a-form-item label="输入">
            <div class="mt-1">
              <a-table
                rowKey="fieldName"
                :dataSource="json"
                :columns="columns"
                size="small"
                :pagination="false"
              ></a-table>
            </div>

            <div>{{ requestJson }}</div>
            <div><a @click="doCopy(JSON.stringify(requestJson))">复制json</a></div>
          </a-form-item>

          <a-form-item label="结果" v-if="JSON.stringify(responseJson) !== '{}'">
            <div class="mt-1">
              <a-icon v-if="responseSuccess" type="check-circle" class="text-green-500 text-2xl"></a-icon>
              <a-icon v-else type="check-circle" class="text-red-500 text-2xl"></a-icon>
            </div>
            <div>{{ responseJson }}</div>
            <div><a @click="doCopy(JSON.stringify(responseJson))">复制json</a></div>
          </a-form-item>
        </div>
      </a-form>

      <div class="detailView-actions">
        <a-button class="app-btn condition-btns" type="primary" @click="doSave">执行</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import PictureCardListUpload from '@/components/PictureCardListUpload'
export default {
  components: { PictureCardListUpload },

  data() {
    return {
      form: this.$form.createForm(this),
      columns: [
        {
          title: '参数',
          dataIndex: 'fieldName',
        },
        {
          title: '数据值',
          dataIndex: 'value',
          customRender: (text, row) => {
            return <a-input v-model={row.value}></a-input>
          },
        },
        {
          title: '字段说明',
          dataIndex: 'fieldDesc',
        },
        {
          title: '输入案例',
          dataIndex: 'example',
        },
      ],
      detailData: null,

      json: [],
      fileList: [],
      serviceSource: '',
      serviceNo: '',
      responseJson: {},
    }
  },
  computed: {
    requestJson() {
      const json = {}
      this.json.map((item) => {
        json[item.fieldName] = item.value || ''
      })
      return json
    },
    responseSuccess() {
      return this.responseJson?.success === true
    },
  },
  methods: {
    async loadById(id) {
      const res = await this.$axios.post('/ops-server/opsTemplate/get', {
        id,
      })
      const detailData = res.data
      this.detailData = detailData
      this.json = JSON.parse(detailData.registerJson)
    },
    async doSave() {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '操作确认',
          content: '确认执行?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      try {
        const { businessNo, opsLine, opsName, opsType, serviceUrl } = this.detailData
        const res = await this.$axios.post('/ops-server/opsDetails/add', {
          templateNo: businessNo,
          opsLine,
          opsName,
          opsType,
          serviceUrl,
          serviceNo: this.serviceNo,
          requsetNote: JSON.stringify(this.requestJson),
          serviceSource: this.serviceSource,
          opsFiles: this.fileList.map((item) => {
            return {
              fileUrl: item,
            }
          }),
        })
        this.responseJson = res.data
        this.$message.success('执行完毕!')
      } catch (e) {}
    },
    doCopy(text) {
      this.$copyText(text).then(
        (e) => {
          this.$message.success('复制成功')
        },
        (e) => {
          this.$message.error('复制失败')
        }
      )
    },
  },
  async mounted() {
    const id = this.$route.query.templateId
    if (id) {
      this.loadById(id)
    }
  },
}
</script>

<style lang="less" scoped>
.info-table {
  table {
    text-align: center;
    width: 100%;
    line-height: 1.5;
    thead {
      tr {
        background: #f4f4f4;
        font-weight: bold;
      }
    }
    tbody {
      tr {
        td {
          color: #666;
        }
      }
    }
    td {
      border: 1px solid #dfdfdf;
      padding: 14px 8px;
    }
    border-collapse: collapse;
  }
}
</style>
