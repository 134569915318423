<template>
  <div class="clearfix">
    <a-upload
      list-type="picture-card"
      :file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :beforeUpload="beforeUpload"
      :customRequest="doUpload"
      :disabled="disabled"
    >
      <div v-if="fileList.length < maxCount">
        <app-icon type="icon-tianjia" />
        <div class="ant-upload-text">点击上传</div>
      </div>
    </a-upload>
  </div>
</template>
<script>
import { ossUpload } from '@/service/oss'
import moment from 'moment'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  data() {
    return {
      fileList: [],
    }
  },
  props: {
    value: {
      type: Array,
    },
    storeAs: {
      type: Function,
      default(file, key = '') {
        const suffix = file.name.match(/\.[^ .]+$/)[0]
        return `${key}/${moment().format('YYYY/MM')}/${new Date().getTime()}${suffix}`
      },
    },
    fileType: {
      type: String,
      default: 'image',
    },
    maxCount: {
      type: Number,
      default: 8,
    },
    maxFileSize: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.resetFileList()
  },
  methods: {
    resetFileList() {
      this.$nextTick(() => {
        if (!this.value) {
          this.fileList = []
        } else {
          this.fileList = this.value
            .filter((v) => !!v)
            .map((item, index) => {
              // 后缀获取
              const matched = item.match(/[^/]+\.[^.]+$/)
              const fileName = matched ? matched[0] : 'image.png'

              const fileItem = {
                uid: `-${index}`,
                name: fileName,
                status: 'done',
                url: item,
              }
              return fileItem
            })
        }
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      // const url = file.url || file.preview
    },
    handleChange({ fileList }) {
      this.fileList = fileList
      const srcList = fileList
        .filter((item) => item.status === 'done' && item.response.url)
        .map((item) => {
          return item.response.url
        })
      // 无变化的情况不触发
      if (this.value || srcList.length) {
        this.$emit('input', srcList)
        this.$emit('change', srcList)
      }
    },
    beforeUpload(file) {
      const { fileType, maxFileSize } = this
      const [type] = file.type.split('/')

      if (fileType) {
        const allowType = fileType.split(',')
        if (!allowType.includes(type)) {
          this.$message.error(`请上传${allowType}类型文件`)
          return false
        }
      }
      const isLtSize = file.size / 1024 / 1024 < maxFileSize
      if (!isLtSize) {
        this.$message.error(`上传文件不能大于${maxFileSize}M!`)
        return false
      }
    },
    doUpload(ev) {
      const { file, onError, onProgress, onSuccess } = ev

      const storeAs = this.storeAs(file)
      ossUpload(file, storeAs, {
        progress: (percent) => {
          onProgress(
            {
              percent,
            },
            file
          )
        },
      })
        .then((path) => {
          // 会写入 file.response
          onSuccess(
            {
              url: path,
            },
            file
          )
        })
        .catch((e) => {
          console.log(e)
          onError(e)
        })
    },
  },
}
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
/* .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
} */
</style>
